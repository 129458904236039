import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function NeonText({
  children,
  className,
}: {
  children: JSX.Element | string;
  className?: string;
}) {
  return (
    <h2
      className={twMerge(
        'text-[17px] mt-1 font-[800] sm:text-[35px] lg:text-[45px] text-center text-white 2xl:mt-5 2xl:text-[60px] uppercase',
        className,
      )}
      style={{
        textShadow:
          'rgb(255 71 152 / 25%) 0px 0px 2px, rgb(255 71 152 / 25%) 0px 0px 10px, rgb(255 71 152 / 25%) 0px 0px 8px, rgb(255 71 152 / 25%) 0px 0px 23px, rgb(255 71 152 / 25%) 0px 0px 18px, rgb(255 71 152 / 25%) 0px 0px 17px, rgb(255 71 152 / 25%) 0px 0px 7px, rgb(255 71 152 / 25%) 1px 1px 21px',
      }}
    >
      {children}
    </h2>
  );
}
