import { useCallback, useMemo, useState } from 'react';
import Link from 'next/link';
import { useForm, Controller, ValidationRule } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import { useMutation } from '@apollo/client';
import { handleUserErrors } from '@flyer/utils';
import { toast } from '@flyer/components';
import { useRouter } from 'next/router';
import NeonText from '@/components/common/NeonText';
import Button from '@/components/common/Modal/components/ModalButton';
import ROUTES from '@/lib/routes';
import Alert from '@/components/common/Alert';
import { usernameRegex } from '@/lib/constant';
import { graphql } from '@/__generated__';
import { ExamDomainQuery, UserErrorCode } from '@/__generated__/graphql';
import { useAuth } from '@/providers/auth';
import GoogleLoginButton from './common/GoogleLoginButton';
import PhoneInput from './common/PhoneInput';
import PasswordInput from './common/PasswordInput';
import AuthLayout from './AuthLayout';

const LoginViewLogInWithPasswordMutation = graphql(`
  mutation LoginViewLogInWithPasswordMutation($input: ViewerLogInWithPasswordInput!) {
    viewerLogIn(input: { withPassword: $input }) {
      viewer {
        ...Viewer
      }
      userErrors {
        code
        field
        message
        param {
          value
        }
      }
    }
  }
`);

export type LoginForm = {
  identifier: string;
  password: string;
};

const LoginSuccessMessageID = 'login_success_message';

export default function LoginView({ dataDomain }: { dataDomain?: ExamDomainQuery }) {
  const { writeQueryViewer } = useAuth();
  const { t } = useTranslation('auth');
  const [errorMessage, setErrorMessage] = useState('');

  const router = useRouter();

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    setError,
    watch,
  } = useForm<LoginForm>({ defaultValues: { identifier: '', password: '' } });

  const [login, { loading }] = useMutation(LoginViewLogInWithPasswordMutation, {
    context: { v2: true },
    onCompleted({ viewerLogIn }) {
      if (viewerLogIn.userErrors.length > 0) {
        handleUserErrors<keyof LoginForm>(viewerLogIn.userErrors, {
          setOperationError: setErrorMessage,
          setFieldError: (field, message) => setError(field, { message }),
          operationKeys: {
            [UserErrorCode.Invalid]: t('Login.invalid_credentials'),
          },
        });
        toast.dismiss(LoginSuccessMessageID);
      } else {
        setErrorMessage('');
        toast.success(t('Login.success'), { id: LoginSuccessMessageID, duration: 2000 });
        setTimeout(() => toast.dismiss(LoginSuccessMessageID), 2000);
        writeQueryViewer(viewerLogIn);
      }
    },
  });

  const identifierValue = watch('identifier', '');
  const identifierValidationPattern = useMemo<ValidationRule<RegExp> | undefined>(() => {
    const hasCharacter = /[A-Za-z]/.test(identifierValue);

    return hasCharacter
      ? {
          value: usernameRegex,
          message: t('form.username.invalid'),
        }
      : undefined;
  }, [identifierValue, t]);

  const onSubmit = useCallback(
    ({ identifier, password }: LoginForm) => {
      return login({ variables: { input: { identifier, password } } });
    },
    [login],
  );

  return (
    <AuthLayout dataDomain={dataDomain}>
      <div>
        <NeonText className="2xl:text-[50px] text-[38px]">{t('Login.title')}</NeonText>
        {/* <h3 className="text-white uppercase font-[700] text-[1rem] md:text-[1.25rem]">
          {t('Login.sub_title')}
        </h3> */}
      </div>

      <GoogleLoginButton />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          {errorMessage && <Alert status="error">{errorMessage}</Alert>}
          <Controller
            control={control}
            name="identifier"
            rules={{
              required: t('form.phone_number.empty'),
              pattern: identifierValidationPattern,
            }}
            render={({ field }) => {
              return (
                <PhoneInput
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={errors.identifier?.message}
                  isDefaultShowCountryCode={false}
                />
              );
            }}
          />
          <PasswordInput
            {...register('password', { required: t('form.password.empty') })}
            placeholder={t('form.password.placeholder')}
            error={errors.password?.message}
          />
          <Link
            href={ROUTES.RECOVER}
            className="text-[#7BFAFC] self-center text-[1rem] font-[700] hover:text-[#6ee1e2]"
          >
            {t('Login.forgot_password_link')}
          </Link>
          <Button
            className="uppercase text-white"
            wrapClassName="w-[12.6875rem] m-auto"
            colorScheme="orange"
            size="md"
            type="submit"
            isLoading={loading}
          >
            {t('Login.sign_in_btn')}
          </Button>
          <p className="text-[1rem] font-[700]">
            <span className="text-white">{t('Login.sign_up_prefix')} </span>
            <span>
              <Link
                href={{
                  pathname: '/signup',
                  query: router.query,
                }}
                className="text-[#7BFAFC] hover:text-[#6ee1e2]"
              >
                {t('Login.sign_up_link')}
              </Link>
            </span>
          </p>
        </div>
      </form>
    </AuthLayout>
  );
}
