'use client';

import { m } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { GoogleAuthProvider, UserCredential, signInWithPopup } from 'firebase/auth';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { buttonAnimation } from '@/lib/animation';
import Loading from '@/components/common/Loading';
import { auth } from '@/lib/firebase';
import { graphql } from '@/__generated__';
import ROUTES from '@/lib/routes';
import { useAuth } from '@/providers/auth';
import GoogleIcon from './ic-google.svg';

const loginWithGoogle = (): Promise<UserCredential> => {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });
  return signInWithPopup(auth, provider);
};

const GoogleLoginButtonLoginMutation = graphql(`
  mutation GoogleLoginButtonLoginMutation($input: ViewerLogInWithFirebaseEmailInput!) {
    viewerLogIn(input: { withFirebaseEmail: $input }) {
      viewer {
        ...Viewer
      }
      providerInfo {
        provider
        email
        isFirstLogin
        needEmailVerified
        associatedPhoneNumber
      }
      userErrors {
        code
        field
        message
        param {
          value
        }
      }
    }
  }
`);

type Props = {
  className?: string;
  fullWidth?: boolean;
};

const GoogleLoginButton = forwardRef(({ className, fullWidth }: Props, ref) => {
  const { t } = useTranslation('common');
  const { writeQueryViewer } = useAuth();
  const router = useRouter();
  const [logIn] = useMutation(GoogleLoginButtonLoginMutation, {
    context: { v2: true },
    onCompleted({ viewerLogIn }) {
      writeQueryViewer(viewerLogIn);
    },
  });
  const [loading, setLoading] = useState(false);
  const handleClick = useCallback(async () => {
    try {
      setLoading(true);
      const { user } = await loginWithGoogle();
      const token = await user.getIdToken();
      const { data } = await logIn({ variables: { input: { token } } });
      const info = data?.viewerLogIn.providerInfo;
      if (info?.needEmailVerified) {
        await router.push(ROUTES.VERIFY_ACCOUNT(), {
          query: {
            ...router.query,
            token,
            email: info.email,
            associatedPhoneNumber: info.associatedPhoneNumber,
          },
        });
      }
    } catch (error) {
      console.error('Error when login with google:', error);
    } finally {
      setLoading(false);
    }
  }, [logIn, router]);

  useImperativeHandle(ref, () => ({
    click: handleClick,
  }));

  return (
    <m.button
      className={twMerge(
        'inline-block rounded-[.5rem]',
        'text-white uppercase relative hover:bg-[#191155] transition-colors',
        fullWidth && 'w-full',
        className,
      )}
      type="button"
      whileTap={buttonAnimation.whileTap}
      onClick={handleClick}
      disabled={loading}
    >
      <div className="absolute inset-0 w-full h-full rounded-[inherit] bg-[#191155] opacity-80" />
      <div className="flex justify-center items-center gap-3 md:gap-5 relative opacity-100 py-3 md:py-4 px-3">
        {loading && <Loading isLoading />}
        <GoogleIcon className="w-[1.4375rem]" />
        <p className="md:text-[1.125rem] text-[.9rem] font-[700] text-center">
          {t('google_sign_in_btn')}
        </p>
      </div>
    </m.button>
  );
});

export default GoogleLoginButton;
