
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { ExamDomainDocument, ExamDomainQuery, ExamDomainQueryVariables, } from '@/__generated__/graphql';
import LoginView from '@/components/auth/LoginView';
import { initializeApollo } from '@/lib/apollo';
import { GetServerSideProps } from 'next';
export default function LoginPage({ dataDomain }: {
    dataDomain?: ExamDomainQuery;
}) {
    return <LoginView dataDomain={dataDomain}/>;
}
const getServerSideProps: GetServerSideProps = async (context) => {
    const client = initializeApollo();
    const urlFull = typeof context?.query?.auth_redirect_url === 'string' &&
        context?.query?.auth_redirect_url.startsWith('http')
        ? new URL(context?.query?.auth_redirect_url)
        : undefined;
    const parts = urlFull?.host?.split('.');
    const subdomain = parts && parts?.length > 2 ? parts[0] : '';
    const { data } = await client.query<ExamDomainQuery, ExamDomainQueryVariables>({
        query: ExamDomainDocument,
        variables: {
            domain: subdomain || ''
        },
        context: {
            v2: true
        }
    });
    return {
        props: { dataDomain: data }
    };
};

    async function __Next_Translate__getServerSideProps__195fb467a28__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__195fb467a28__ as getServerSideProps }
  