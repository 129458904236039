import React from 'react';
import { twMerge } from 'tailwind-merge';
import WarningIcon from './ic-warning.svg';
import ErrorIcon from './ic-error.svg';
import InfoIcon from './ic-info.svg';
import SuccessIcon from './ic-success.svg';

type AlertStatus = 'info' | 'success' | 'warning' | 'error';

type AlertProps = {
  status?: AlertStatus;
  title?: string;
  showIcon?: boolean;
  children: React.ReactNode;
  className?: string;
};

const iconSize = 'w-[1.25rem] h-[1.25rem]';

const icons = {
  info: <InfoIcon className={iconSize} />,
  success: <SuccessIcon className={iconSize} />,
  warning: <WarningIcon className={iconSize} />,
  error: <ErrorIcon className={iconSize} />,
};

const colors = {
  info: 'bg-[#D2E4FC] text-[#1A3255]',
  success: 'bg-[#C5E4B7] text-[#283D1D]',
  warning: 'bg-[#FFF7CD] text-[#7A4F01]',
  error: 'bg-[#FF4842] text-[#ffffff]',
};

export default function Alert({
  status = 'info',
  title,
  children,
  className,
  showIcon = true,
}: AlertProps) {
  return (
    <div
      className={twMerge(
        'flex w-full rounded-[.5rem] p-3 gap-2 items-start',
        colors[status],
        className,
      )}
      role="alert"
    >
      {showIcon && icons[status]}
      <div className="flex flex-col gap-3 w-full text-left">
        {title && (
          <h4 aria-label={title} className="text-[1rem] font-[700]">
            {title}
          </h4>
        )}
        <div className="text-[.875rem]">{children}</div>
      </div>
    </div>
  );
}
